@use '@angular/material' as mat;

// Include non-theme styles for core.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$config: mat.define-legacy-typography-config();

// Define a theme.
$app-primary: mat.define-palette(mat.$cyan-palette);
$app-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn )));

// Include theme styles for core and each component used in your app.
@include mat.all-legacy-component-themes($app-theme);

// Custom CSS starts here
body {
  font-family: mat.font-family($config);
  margin: 0;
}

.app-content {
  flex: 1 0 auto;
  min-height: 470px;
  padding: 10px;

  .mat-card {
    margin: 0 auto;
  }

  .mat-card-actions {
    width: 70%;
    padding: 0 0 20px 80px !important;

    button {
      margin-right: 30px !important;
      text-transform: uppercase;
    }
  }
}

[mat-dialog-title] {
  font-family: mat.font-family($config);
  font-weight: 400 !important;
}

.mat-table {
  .mat-cell:focus {
    outline: none;
  }

  .mat-row {
    cursor: pointer;
  }

  .mat-row:hover {
    background: #f6f6f6;
  }

  .actions-header-cell,
  .actions-cell {
    text-align: right;

    [md-icon-button] {
      color: #444444;
    }
  }
}

.mat-dialog-title {
  display: flex !important;

  span {
    font-size: 20px;
    font-weight: 400;
  }
}

.snackbar-error {
  color: #f44336;
}

.mat-input-suffix {
  cursor: default;
}

/***********Generic Classes*******/
.center {
  text-align: center;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.grid-width-100 {
  width: 100%;
  display: block;
}

.width-50 {
  width: 50%;
}

.margin-15 {
  margin: 15px;
}

.margin-right-15 {
  margin: 15px;
}

.spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}